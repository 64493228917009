import Alpine from "alpinejs"

import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'

declare global {
    interface Window {
        Alpine: typeof Alpine
    }
}

window.Alpine = Alpine
Alpine.plugin(focus)
Alpine.plugin(collapse)

Alpine.data('navigate', () => ({

    active: false,

    openMenu: false,

    openSearch: false,

    init() {

        this.$watch('openMenu', (value) => {
            this.active = value
        })

        this.$watch('openSearch', (value) => {
            this.active = value
        })

        const body = document.body
        if (body) {
            /* Set body overflow when menu is open */
            this.$watch('active', (value) => {
                if (value) {
                    body.classList.add('overflow-hidden')
                } else {
                    body.classList.remove('overflow-hidden')
                }
            })
        }

    },

    /* Toggle the nav menu */
    toggleMenu() {
        this.openSearch = false
        // Set menu height
        this.$refs.menu.style.minHeight = 'calc(100vh - ' + this.$refs.navbar.offsetHeight + 'px)'
        this.openMenu = ! this.openMenu
    },

    /* Toggle the search menu */
    toggleSearch() {
        this.openMenu = false
        this.$refs.search.style.minHeight = 'calc(100vh - ' + this.$refs.navbar.offsetHeight + 'px)'
        this.openSearch = ! this.openSearch
    },

}))

Alpine.data('directoryFilter', (props: { selectedAlpha: string, selectedDepartment: string, selectedDepartmentTitle: string }) => ({
    dropdownOpen: false,

    selectedAlpha: props.selectedAlpha,
    selectedDepartment: props.selectedDepartment,
    selectedDepartmentTitle: props.selectedDepartmentTitle,

    buildQuery() {
        let query = [];

        if (this.selectedAlpha !== '') {
            query.push('q=' + this.selectedAlpha)
        }  if (this.selectedDepartment !== '') {
            query.push('dept=' + this.selectedDepartment)
        }

        if (query.length > 0) {
            return '/about/directory?' + query.join('&')
        } else {
            return '/about/directory'
        }
    },

    init() {
        this.$watch('selectedAlpha', () => {
            window.location.href = this.buildQuery()
        });

        this.$watch('selectedDepartment', () => {
            window.location.href = this.buildQuery()
        })
    }
}))

Alpine.start()

import Glide from "@glidejs/glide";

document.addEventListener('DOMContentLoaded', () => {

    /**
     * Create responsive carousels at small screens
     * @param element
     */
    const setupResponsiveCarousel = (element: HTMLElement) => {
        return new Glide(element, {
            type: 'carousel',
            startAt: 0,
            perView: 1,
            breakpoints: {

            }
        }).mount()
    }

    /**
     * Check screen size and setup response carousels
     */
    const checkScreenSize = () => {

        const responsiveCarousels: NodeListOf<HTMLElement> = document.querySelectorAll('.glide-responsive')
        const responsiveGlides: Glide[] = []

        if (window.innerWidth <= 728) {
            if (responsiveCarousels.length > 0) {
                responsiveCarousels.forEach((element) => {
                    let glide = setupResponsiveCarousel(element)
                    responsiveGlides.push(glide)
                })
            }
        } else {
            responsiveGlides.forEach( (glide) => {
                glide.destroy()
            })
        }
    }

    /**
     * Glide carousel
     */
    const carousels: NodeListOf<HTMLElement> = document.querySelectorAll('.glide-carousel')
    if (carousels.length > 0) {
        carousels.forEach( (element) => {
            new Glide(element, {
                type: 'carousel',
                startAt: 0,
                perView: 1
            }).mount()
        })
    }

    /**
     * Events
     */
    const events: NodeListOf<HTMLElement> = document.querySelectorAll('.glide-events')
    if (events.length > 0) {
        events.forEach( (element) => {
            new Glide(element, {
                type: 'carousel',
                startAt: 0,
                perView: 3,
                autoplay: 4000,
                peek: { before: 0, after: 100 },
                breakpoints: {
                    768: {
                        perView: 1,
                        peek: 0
                    }
                }
            }).mount()
        })
    }

    checkScreenSize()

})
